.navbar {
  background-color: rgb(33, 37, 41);
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  color: white;
  justify-content: center;
  height: 60px;
}

/* .container {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
} */

.navbar-items {
  display: flex;
  justify-content: center;
  color: white;
  width: 100%;
}

.navbar.light-theme {
  background-color: white;
  color: white;
  border-color: #e8e8e8;
}

.navbar.dark-theme {
  background-color: rgb(33, 37, 41);
  color: white;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgb(33, 37, 41) !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: white !important;
}

.text-center {
  text-align: center !important;
}
.m-0 {
  margin: 0 !important;
}